import memoize from 'lodash/memoize'

export const debugLog = (label: string, ...values: any[]): any => {
  console.log(`%c//APSYS-log//: ${label}`, 'color: goldenrod;', ...values)
}

export const debugError = (label: string, ...values: any[]): any => {
  console.log(`%c//APSYS-error//: ${label}`, 'color: red;', ...values)
}

/**
 * This doesn't allow a debug error to be run more than once.
 */
export const memoizedDebugError = memoize(debugError)

export const debugPerformance = (
  label: string,
  t1: number,
  t0: number,
): any => {
  if (import.meta.env.DEV) {
    console.info(
      '%c//APSYS-performance//',
      'color: orange;',
      `${label}:`,
      `${t1 - t0} milliseconds.`,
    )
  }
}
