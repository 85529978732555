import { z } from 'zod'

const AssetSchema = z.object({
  ref: z.object({
    typename: z.string(),
    id: z.string(),
  }),
  url: z.string(),
  contentType: z.string(),
  language: z.string().optional(),
  copyright: z.string().optional(),
  distributionControl: z.enum(['unrestricted', 'onsite', 'admin-only']),
  externalId: z.string().optional(),
})

const CacheAssetEventSchema = z.object({
  type: z.literal('CACHE_ASSETS'),
  /**
   * The ids of all related entities that should be cached
   */
  cachingIds: z.array(z.string()),
  assets: z.array(AssetSchema),
})

const AnalyticsEventSchema = z.object({
  type: z.literal('ANALYTICS_EVENT'),
  // The service worker assumes this is
  // correct and validated in the main thread
  eventSend: z.object({}).passthrough(),
})

/**
 * This makes sure the analytics is synced after the
 * app is refreshed or opened in a new tab.
 *
 * The queue doesn't sync after a refresh, so we need to
 * manually sync it.
 */
const AnalyticsQueueSyncEventSchema = z.object({
  type: z.literal('ANALYTICS_QUEUE_SYNC_EVENT'),
})

export const WorkerEventSchema = z.discriminatedUnion('type', [
  CacheAssetEventSchema,
  AnalyticsEventSchema,
  AnalyticsQueueSyncEventSchema,
])

export type CacheAssetsEvent = z.infer<typeof CacheAssetEventSchema>
export type AnalyticsEvent = z.infer<typeof AnalyticsEventSchema>
export type AnalyticsQueueSyncEvent = z.infer<
  typeof AnalyticsQueueSyncEventSchema
>

export type ToWorkerEvent = z.infer<typeof WorkerEventSchema>

export type WorkerEventMap = {
  [K in ToWorkerEvent['type']]: Extract<ToWorkerEvent, { type: K }>
}

export enum WorkerFetchResultType {
  Success = 'SUCCESS',
  Error = 'ERROR',
  TerminalError = 'TERMINAL_ERROR',
}

export type WorkerEventResponse<TResult = unknown> = {
  status: WorkerFetchResultType
  result?: TResult
  error?: unknown
}

export type ToWorkerEventHandlerMap = {
  [K in keyof WorkerEventMap]: (event: WorkerEventMap[K]) => Promise<void>
}
