import { GazelleRef } from '@apsys/gazelle'

import { PWACatalogObject } from 'hooks/useCatalogObjectList'
import { PWASpace } from 'hooks/usePWASpace'

// For komodo analytics calls. "Contexts" describe the scenario
// that you are in UI wise. eg. Object inside zone inside tour.
export const getContexts = (
  pwaEntity: PWACatalogObject | PWASpace,
  withCatalogObjectRef: boolean = false,
): Array<GazelleRef> => {
  // Type guard for experience-laden catalog objects
  const contexts: Array<GazelleRef> = []

  if ('feature' in pwaEntity) {
    if (pwaEntity.content) {
      contexts.push(pwaEntity.content.ref)
    }
  }

  if ('catalogObject' in pwaEntity) {
    if (withCatalogObjectRef) {
      contexts.push(pwaEntity.catalogObject.ref)
    }

    if (pwaEntity.source === 'immersive-tour') {
      contexts.push(pwaEntity.tour.ref)
      contexts.push(pwaEntity.scene.ref)
    }
  }

  contexts.push(pwaEntity.vxConfig.ref)

  return contexts
}

/**
 * @see https://www.typescriptlang.org/docs/handbook/advanced-types.html#exhaustiveness-checking
 * @category Utils
 */
export function assertNever(x: never): never {
  throw new Error(`Unexpected object: ${JSON.stringify(x)}`)
}

/**
 * Ensures that the input is not null or undefined while maintaining the type safety.
 */
export const notNil = <T>(input: T | null | undefined): input is T => !!input
