import memoize from 'lodash/memoize'
import { Location } from 'react-router-dom'
import { UAParser } from 'ua-parser-js'
import z from 'zod'

import { EventMetadata } from '../schemas/komodo-events'
import { KomodoQueueDB } from '../storage/KomodoQueueDB'
import {
  getAudience,
  getDeviceID,
  getEventManifest,
} from '../storage/local-storage'
import {
  ToWorkerEvent,
  WorkerEventResponse,
  WorkerFetchResultType,
} from '../types/worker'

import { debugLog } from './debug'
import getEnv from './getEnv'

/**
 * A list of places we can get an object from
 */
export type KomodoEventSource =
  | 'list'
  | 'url'
  | 'search'
  | 'wall-id'
  | 'qr'
  | 'my-visit'
  | undefined

/**
 * Get the source of the event from the router state
 *
 * @param location the location object from react-router-dom
 * @returns the source of the event
 */
export function getEventSource(location: Location): KomodoEventSource {
  const STATE_SCHEMA = z
    .object({
      source: z.enum(['list', 'url', 'search', 'wall-id', 'qr', 'my-visit']),
    })
    .transform(elem => elem.source)

  const parsed = STATE_SCHEMA.safeParse(location.state)
  return parsed.success ? parsed.data : 'url'
}

/**
 * Sends an event to the service worker
 * Create an event request object for Komodo
 * @param eventSend the event to send to Komodo, this has been validated in the main thread
 * @returns a request object for Komodo
 */
function createEventRequest(eventSend: unknown): Request {
  const env = getEnv()
  if (!env.VITE_KOMODO_DOMAIN) {
    throw new Error('Missing KOMODO_DOMAIN')
  }

  return new Request(env.VITE_KOMODO_DOMAIN, {
    method: 'POST',
    body: JSON.stringify(eventSend),
    headers: new Headers({
      'Content-Type': 'application/json',
    }),
    keepalive: true, // Use keepalive for requests in service worker
  })
}

/**
 * Sends an event to the service worker to be
 * processed by Komodo.
 */
export function sendWorkerEvent(workerEvent: ToWorkerEvent): void {
  const { controller } = navigator.serviceWorker ?? {}
  if (!controller) {
    return
  }

  controller.postMessage(workerEvent)
}

/**
 * Manages the queueing of analytics events
 * and its messaging between the service worker and
 * the application.
 */
export async function manageAnalyticsEventQueueing(): Promise<void> {
  const registration = await navigator.serviceWorker.ready
  try {
    // registers the sync event for the komodo-queue
    await registration.sync.register(KomodoQueueDB.syncTag)
  } catch (error) {
    debugLog('Failed to register sync', error)
  }

  // After registration is ready, send this event
  sendWorkerEvent({ type: 'ANALYTICS_QUEUE_SYNC_EVENT' })

  window.addEventListener('online', async () => {
    sendWorkerEvent({ type: 'ANALYTICS_QUEUE_SYNC_EVENT' })
  })
}

/**
 * Does requests and returns error results, allowing to distinguish between
 * terminal errors and recoverable errors.
 */
async function fetchWithFailureCheck(
  request: Request,
): Promise<{ status: WorkerFetchResultType; response: Response | null }> {
  try {
    const response = await fetch(request)

    if (
      response.status >= 400 &&
      response.status < 600 &&
      // Should retry with 429 as it's a rate limit error
      response.status !== 429
    ) {
      debugLog('Terminal error during fetch:', response)
      return { status: WorkerFetchResultType.TerminalError, response }
    }

    if (!response.ok) {
      debugLog('Error during fetch:', response)
      return { status: WorkerFetchResultType.Error, response }
    }

    return { status: WorkerFetchResultType.Success, response }
  } catch (error) {
    debugLog('Error during fetch:', error)
    return { status: WorkerFetchResultType.Error, response: null }
  }
}

export async function sendAnalyticsEvent(
  event: unknown,
): Promise<WorkerEventResponse> {
  const request = createEventRequest(event)

  const { status, response } = await fetchWithFailureCheck(request)

  const json = await response?.json()

  if (status !== WorkerFetchResultType.Success) {
    return {
      status,
      error: {
        code: 0,
        detail: json ?? 'Event could not be sent and is unrecoverable',
      },
    }
  }

  return { status, result: json }
}

/**
 * Get the metadata for Komodo events.
 *
 * This function is memoized because the data is static for the lifetime of the app.
 *
 * @returns metadata for Komodo events
 */
export const getEventMetadata = memoize((): EventMetadata | null => {
  const deviceId = getDeviceID()
  const env = getEnv()
  const manifest = getEventManifest()
  const audience = getAudience()

  const {
    os: { version: deviceVersion, name: deviceName },
    // eslint-disable-next-line new-cap
  } = new UAParser().getResult()

  /** If the bundle is loaded return the metadata for Komodo events */
  if (!deviceId || !manifest) return null

  return new EventMetadata({
    client: env.VITE_CLIENT || '',
    project: env.VITE_PROJECT || '',
    component: env.VITE_COMPONENT || '',
    environment: env.VITE_DEPLOY_ENV || '',
    audience,
    manifest,
    appVersion: env.VITE_APP_VERSION || '',
    appBuildConfig: import.meta.env.PROD ? 'release' : 'debug',
    deviceId,
    devicePlatform: (deviceName || '').toLocaleLowerCase(),
    devicePlatformVersion: deviceVersion || '',
    deviceRole: 'byod',
  })
})
