/* eslint-disable */
import * as z from 'zod'

import {
  Struct,
  Entity,
  Meta,
  recase,
  GazelleRef,
  GazelleRefSchema,
} from '@apsys/gazelle'
import { BundleLoader, GazelleEntityFactory, ValueOf } from '@apsys/gazelle'
import { ReffedEntity as ReffableEntity, WithRef } from '@apsys/gazelle'

import * as VixenCoreManifest from './vixen-core-manifest'
import * as VixenCore from './vixen-core'
import * as VixenAssets from './vixen-assets'
import * as VixenSpatial from './vixen-spatial'

export const meta: Meta = {
  name: 'Komodo Core Events',
  moduleName: 'komodo-core',
  version: '0.11.0',
  authors: [],
}

// === enums ===
// --- device-network ---
export enum DeviceNetwork {
  WIFI = 'wifi',
  MOBILE = 'mobile',
  UNKNOWN = 'unknown',
}

// === interfaces ===
// --- event ---
export interface EventSchema {
  timestamp: string
  'device-battery-level'?: number | null
  'device-network'?: DeviceNetwork | null
  contexts: Array<GazelleRefSchema>
  location?: LocationSchema | null
  language: string
  source?: string | null
  settings: any
}

export interface IEvent {
  timestamp: string
  deviceBatteryLevel?: number
  deviceNetwork?: DeviceNetwork
  contexts: Array<GazelleRef<VixenCore.INamed>>
  location?: Location
  language: string
  source?: string
  settings: any
}

// === structs ===
export interface LocationSchema {
  venue?: VixenSpatial.VenueRefSchema | null
  spaces?: Array<VixenSpatial.FeatureRefSchema> | null
}

export interface ILocation {
  venue?: VixenSpatial.VenueRef
  spaces?: Array<VixenSpatial.FeatureRef>
}

type LocationSchemaType = z.Schema<Location, z.ZodTypeDef, LocationSchema>

export const LocationSchema = z
  .lazy(() =>
    z.object({
      venue: VixenSpatial.VenueRefSchema.nullish(),
      spaces: z.array(VixenSpatial.FeatureRefSchema).nullish(),
    }),
  )
  .transform(value => new Location(recase(value))) satisfies LocationSchemaType

export class Location extends Struct<LocationSchema> implements ILocation {
  static readonly typename = 'location'
  static readonly schema: LocationSchemaType = LocationSchema
  static readonly parse = LocationSchema.parse
  static readonly fields = ['venue', 'spaces']

  readonly typename = 'location'

  venue?: VixenSpatial.VenueRef
  spaces?: Array<VixenSpatial.FeatureRef>

  constructor(attrs: ILocation) {
    super(attrs)
  }
}

export interface EventMetadataSchema {
  client: string
  project: string
  component: string
  environment: string
  audience: VixenCoreManifest.Audience
  manifest: GazelleRefSchema
  'app-version': string
  'app-build-config': string
  'device-id': string
  'device-platform': string
  'device-platform-version': string
  'device-role': string
}

export interface IEventMetadata {
  client: string
  project: string
  component: string
  environment: string
  audience: VixenCoreManifest.Audience
  manifest: GazelleRef<VixenCoreManifest.Manifest>
  appVersion: string
  appBuildConfig: string
  deviceId: string
  devicePlatform: string
  devicePlatformVersion: string
  deviceRole: string
}

type EventMetadataSchemaType = z.Schema<
  EventMetadata,
  z.ZodTypeDef,
  EventMetadataSchema
>

export const EventMetadataSchema = z
  .lazy(() =>
    z.object({
      client: z.string(),
      project: z.string(),
      component: z.string(),
      environment: z.string(),
      audience: z.nativeEnum(VixenCoreManifest.Audience),
      manifest: GazelleRefSchema,
      'app-version': z.string(),
      'app-build-config': z.string(),
      'device-id': z.string().nonempty(),
      'device-platform': z.string(),
      'device-platform-version': z.string(),
      'device-role': z.string(),
    }),
  )
  .transform(
    value => new EventMetadata(recase(value)),
  ) satisfies EventMetadataSchemaType

export class EventMetadata
  extends Struct<EventMetadataSchema>
  implements IEventMetadata
{
  static readonly typename = 'event-metadata'
  static readonly schema: EventMetadataSchemaType = EventMetadataSchema
  static readonly parse = EventMetadataSchema.parse
  static readonly fields = [
    'client',
    'project',
    'component',
    'environment',
    'audience',
    'manifest',
    'app-version',
    'app-build-config',
    'device-id',
    'device-platform',
    'device-platform-version',
    'device-role',
  ]

  readonly typename = 'event-metadata'

  client!: string
  project!: string
  component!: string
  environment!: string
  audience!: VixenCoreManifest.Audience
  manifest!: GazelleRef<VixenCoreManifest.Manifest>
  appVersion!: string
  appBuildConfig!: string
  deviceId!: string
  devicePlatform!: string
  devicePlatformVersion!: string
  deviceRole!: string

  constructor(attrs: IEventMetadata) {
    super(attrs)
  }
}

// === entities ===
export interface AppStateChangedSchema extends EventSchema {
  state: 'foregrounded' | 'backgrounded'
}

export interface IAppStateChanged extends IEvent {
  state: 'foregrounded' | 'backgrounded'
}

type AppStateChangedSchemaType = z.Schema<
  AppStateChanged,
  z.ZodTypeDef,
  AppStateChangedSchema
>

export const AppStateChangedSchema = z
  .lazy(() =>
    z.object({
      ref: GazelleRefSchema,
      timestamp: z.string(),
      'device-battery-level': z.number().nullish(),
      'device-network': z.nativeEnum(DeviceNetwork).nullish(),
      contexts: z.array(GazelleRefSchema),
      location: LocationSchema.nullish(),
      language: z.string(),
      source: z.string().nullish(),
      settings: z.object({}).passthrough(),
      state: z.enum(['foregrounded', 'backgrounded']),
    }),
  )
  .transform(
    value => new AppStateChanged(recase(value)),
  ) satisfies AppStateChangedSchemaType

export class AppStateChanged
  extends Entity<AppStateChangedSchema>
  implements IAppStateChanged
{
  static readonly typename = 'app-state-changed'
  static readonly schema: AppStateChangedSchemaType = AppStateChangedSchema
  static readonly parse = AppStateChangedSchema.parse
  static readonly fields = [
    'ref',
    'timestamp',
    'device-battery-level',
    'device-network',
    'contexts',
    'location',
    'language',
    'source',
    'settings',
    'state',
  ]

  readonly typename = 'app-state-changed'

  ref!: GazelleRef<AppStateChanged>
  timestamp!: string
  deviceBatteryLevel?: number
  deviceNetwork?: DeviceNetwork
  contexts!: Array<GazelleRef<VixenCore.INamed>>
  location?: Location
  language!: string
  source?: string
  settings!: any
  state!: 'foregrounded' | 'backgrounded'

  constructor(attrs: IAppStateChanged & WithRef<AppStateChanged>) {
    super(attrs)
  }
}

export interface AppResetSchema extends EventSchema {}

export interface IAppReset extends IEvent {}

type AppResetSchemaType = z.Schema<AppReset, z.ZodTypeDef, AppResetSchema>

export const AppResetSchema = z
  .lazy(() =>
    z.object({
      ref: GazelleRefSchema,
      timestamp: z.string(),
      'device-battery-level': z.number().nullish(),
      'device-network': z.nativeEnum(DeviceNetwork).nullish(),
      contexts: z.array(GazelleRefSchema),
      location: LocationSchema.nullish(),
      language: z.string(),
      source: z.string().nullish(),
      settings: z.object({}).passthrough(),
    }),
  )
  .transform(value => new AppReset(recase(value))) satisfies AppResetSchemaType

export class AppReset extends Entity<AppResetSchema> implements IAppReset {
  static readonly typename = 'app-reset'
  static readonly schema: AppResetSchemaType = AppResetSchema
  static readonly parse = AppResetSchema.parse
  static readonly fields = [
    'ref',
    'timestamp',
    'device-battery-level',
    'device-network',
    'contexts',
    'location',
    'language',
    'source',
    'settings',
  ]

  readonly typename = 'app-reset'

  ref!: GazelleRef<AppReset>
  timestamp!: string
  deviceBatteryLevel?: number
  deviceNetwork?: DeviceNetwork
  contexts!: Array<GazelleRef<VixenCore.INamed>>
  location?: Location
  language!: string
  source?: string
  settings!: any

  constructor(attrs: IAppReset & WithRef<AppReset>) {
    super(attrs)
  }
}

export interface PlayableStartedSchema extends EventSchema {
  target: GazelleRefSchema
  'target-group'?: GazelleRefSchema | null
  duration: number
}

export interface IPlayableStarted extends IEvent {
  target: GazelleRef<VixenCore.IPlayable>
  targetGroup?: GazelleRef<VixenAssets.IAssetGroup>
  duration: number
}

type PlayableStartedSchemaType = z.Schema<
  PlayableStarted,
  z.ZodTypeDef,
  PlayableStartedSchema
>

export const PlayableStartedSchema = z
  .lazy(() =>
    z.object({
      ref: GazelleRefSchema,
      timestamp: z.string(),
      'device-battery-level': z.number().nullish(),
      'device-network': z.nativeEnum(DeviceNetwork).nullish(),
      contexts: z.array(GazelleRefSchema),
      location: LocationSchema.nullish(),
      language: z.string(),
      source: z.string().nullish(),
      settings: z.object({}).passthrough(),
      target: GazelleRefSchema,
      'target-group': GazelleRefSchema.nullish(),
      duration: z.number(),
    }),
  )
  .transform(
    value => new PlayableStarted(recase(value)),
  ) satisfies PlayableStartedSchemaType

export class PlayableStarted
  extends Entity<PlayableStartedSchema>
  implements IPlayableStarted
{
  static readonly typename = 'playable-started'
  static readonly schema: PlayableStartedSchemaType = PlayableStartedSchema
  static readonly parse = PlayableStartedSchema.parse
  static readonly fields = [
    'ref',
    'timestamp',
    'device-battery-level',
    'device-network',
    'contexts',
    'location',
    'language',
    'source',
    'settings',
    'target',
    'target-group',
    'duration',
  ]

  readonly typename = 'playable-started'

  ref!: GazelleRef<PlayableStarted>
  timestamp!: string
  deviceBatteryLevel?: number
  deviceNetwork?: DeviceNetwork
  contexts!: Array<GazelleRef<VixenCore.INamed>>
  location?: Location
  language!: string
  source?: string
  settings!: any
  target!: GazelleRef<VixenCore.IPlayable>
  targetGroup?: GazelleRef<VixenAssets.IAssetGroup>
  duration!: number

  constructor(attrs: IPlayableStarted & WithRef<PlayableStarted>) {
    super(attrs)
  }
}

export interface PlayableEndedSchema extends EventSchema {
  target: GazelleRefSchema
  'target-group'?: GazelleRefSchema | null
  duration: number
  'started-event': GazelleRefSchema
  'start-time': string
  'end-time': string
  'duration-played': number
}

export interface IPlayableEnded extends IEvent {
  target: GazelleRef<VixenCore.IPlayable>
  targetGroup?: GazelleRef<VixenAssets.IAssetGroup>
  duration: number
  startedEvent: GazelleRef<PlayableStarted>
  startTime: string
  endTime: string
  durationPlayed: number
}

type PlayableEndedSchemaType = z.Schema<
  PlayableEnded,
  z.ZodTypeDef,
  PlayableEndedSchema
>

export const PlayableEndedSchema = z
  .lazy(() =>
    z.object({
      ref: GazelleRefSchema,
      timestamp: z.string(),
      'device-battery-level': z.number().nullish(),
      'device-network': z.nativeEnum(DeviceNetwork).nullish(),
      contexts: z.array(GazelleRefSchema),
      location: LocationSchema.nullish(),
      language: z.string(),
      source: z.string().nullish(),
      settings: z.object({}).passthrough(),
      target: GazelleRefSchema,
      'target-group': GazelleRefSchema.nullish(),
      duration: z.number(),
      'started-event': GazelleRefSchema,
      'start-time': z.string(),
      'end-time': z.string(),
      'duration-played': z.number(),
    }),
  )
  .transform(
    value => new PlayableEnded(recase(value)),
  ) satisfies PlayableEndedSchemaType

export class PlayableEnded
  extends Entity<PlayableEndedSchema>
  implements IPlayableEnded
{
  static readonly typename = 'playable-ended'
  static readonly schema: PlayableEndedSchemaType = PlayableEndedSchema
  static readonly parse = PlayableEndedSchema.parse
  static readonly fields = [
    'ref',
    'timestamp',
    'device-battery-level',
    'device-network',
    'contexts',
    'location',
    'language',
    'source',
    'settings',
    'target',
    'target-group',
    'duration',
    'started-event',
    'start-time',
    'end-time',
    'duration-played',
  ]

  readonly typename = 'playable-ended'

  ref!: GazelleRef<PlayableEnded>
  timestamp!: string
  deviceBatteryLevel?: number
  deviceNetwork?: DeviceNetwork
  contexts!: Array<GazelleRef<VixenCore.INamed>>
  location?: Location
  language!: string
  source?: string
  settings!: any
  target!: GazelleRef<VixenCore.IPlayable>
  targetGroup?: GazelleRef<VixenAssets.IAssetGroup>
  duration!: number
  startedEvent!: GazelleRef<PlayableStarted>
  startTime!: string
  endTime!: string
  durationPlayed!: number

  constructor(attrs: IPlayableEnded & WithRef<PlayableEnded>) {
    super(attrs)
  }
}

export interface VisitableStartedSchema extends EventSchema {
  target: GazelleRefSchema
}

export interface IVisitableStarted extends IEvent {
  target: GazelleRef<VixenCore.IVisitable>
}

type VisitableStartedSchemaType = z.Schema<
  VisitableStarted,
  z.ZodTypeDef,
  VisitableStartedSchema
>

export const VisitableStartedSchema = z
  .lazy(() =>
    z.object({
      ref: GazelleRefSchema,
      timestamp: z.string(),
      'device-battery-level': z.number().nullish(),
      'device-network': z.nativeEnum(DeviceNetwork).nullish(),
      contexts: z.array(GazelleRefSchema),
      location: LocationSchema.nullish(),
      language: z.string(),
      source: z.string().nullish(),
      settings: z.object({}).passthrough(),
      target: GazelleRefSchema,
    }),
  )
  .transform(
    value => new VisitableStarted(recase(value)),
  ) satisfies VisitableStartedSchemaType

export class VisitableStarted
  extends Entity<VisitableStartedSchema>
  implements IVisitableStarted
{
  static readonly typename = 'visitable-started'
  static readonly schema: VisitableStartedSchemaType = VisitableStartedSchema
  static readonly parse = VisitableStartedSchema.parse
  static readonly fields = [
    'ref',
    'timestamp',
    'device-battery-level',
    'device-network',
    'contexts',
    'location',
    'language',
    'source',
    'settings',
    'target',
  ]

  readonly typename = 'visitable-started'

  ref!: GazelleRef<VisitableStarted>
  timestamp!: string
  deviceBatteryLevel?: number
  deviceNetwork?: DeviceNetwork
  contexts!: Array<GazelleRef<VixenCore.INamed>>
  location?: Location
  language!: string
  source?: string
  settings!: any
  target!: GazelleRef<VixenCore.IVisitable>

  constructor(attrs: IVisitableStarted & WithRef<VisitableStarted>) {
    super(attrs)
  }
}

export interface VisitableEndedSchema extends EventSchema {
  target: GazelleRefSchema
  'started-event': GazelleRefSchema
  'start-time': string
  'end-time': string
}

export interface IVisitableEnded extends IEvent {
  target: GazelleRef<VixenCore.IVisitable>
  startedEvent: GazelleRef<VisitableStarted>
  startTime: string
  endTime: string
}

type VisitableEndedSchemaType = z.Schema<
  VisitableEnded,
  z.ZodTypeDef,
  VisitableEndedSchema
>

export const VisitableEndedSchema = z
  .lazy(() =>
    z.object({
      ref: GazelleRefSchema,
      timestamp: z.string(),
      'device-battery-level': z.number().nullish(),
      'device-network': z.nativeEnum(DeviceNetwork).nullish(),
      contexts: z.array(GazelleRefSchema),
      location: LocationSchema.nullish(),
      language: z.string(),
      source: z.string().nullish(),
      settings: z.object({}).passthrough(),
      target: GazelleRefSchema,
      'started-event': GazelleRefSchema,
      'start-time': z.string(),
      'end-time': z.string(),
    }),
  )
  .transform(
    value => new VisitableEnded(recase(value)),
  ) satisfies VisitableEndedSchemaType

export class VisitableEnded
  extends Entity<VisitableEndedSchema>
  implements IVisitableEnded
{
  static readonly typename = 'visitable-ended'
  static readonly schema: VisitableEndedSchemaType = VisitableEndedSchema
  static readonly parse = VisitableEndedSchema.parse
  static readonly fields = [
    'ref',
    'timestamp',
    'device-battery-level',
    'device-network',
    'contexts',
    'location',
    'language',
    'source',
    'settings',
    'target',
    'started-event',
    'start-time',
    'end-time',
  ]

  readonly typename = 'visitable-ended'

  ref!: GazelleRef<VisitableEnded>
  timestamp!: string
  deviceBatteryLevel?: number
  deviceNetwork?: DeviceNetwork
  contexts!: Array<GazelleRef<VixenCore.INamed>>
  location?: Location
  language!: string
  source?: string
  settings!: any
  target!: GazelleRef<VixenCore.IVisitable>
  startedEvent!: GazelleRef<VisitableStarted>
  startTime!: string
  endTime!: string

  constructor(attrs: IVisitableEnded & WithRef<VisitableEnded>) {
    super(attrs)
  }
}

export interface RoutableStartedSchema extends EventSchema {
  target: GazelleRefSchema
  parameters: any
}

export interface IRoutableStarted extends IEvent {
  target: GazelleRef<VixenCore.IRoutable>
  parameters: any
}

type RoutableStartedSchemaType = z.Schema<
  RoutableStarted,
  z.ZodTypeDef,
  RoutableStartedSchema
>

export const RoutableStartedSchema = z
  .lazy(() =>
    z.object({
      ref: GazelleRefSchema,
      timestamp: z.string(),
      'device-battery-level': z.number().nullish(),
      'device-network': z.nativeEnum(DeviceNetwork).nullish(),
      contexts: z.array(GazelleRefSchema),
      location: LocationSchema.nullish(),
      language: z.string(),
      source: z.string().nullish(),
      settings: z.object({}).passthrough(),
      target: GazelleRefSchema,
      parameters: z.object({}).passthrough(),
    }),
  )
  .transform(
    value => new RoutableStarted(recase(value)),
  ) satisfies RoutableStartedSchemaType

export class RoutableStarted
  extends Entity<RoutableStartedSchema>
  implements IRoutableStarted
{
  static readonly typename = 'routable-started'
  static readonly schema: RoutableStartedSchemaType = RoutableStartedSchema
  static readonly parse = RoutableStartedSchema.parse
  static readonly fields = [
    'ref',
    'timestamp',
    'device-battery-level',
    'device-network',
    'contexts',
    'location',
    'language',
    'source',
    'settings',
    'target',
    'parameters',
  ]

  readonly typename = 'routable-started'

  ref!: GazelleRef<RoutableStarted>
  timestamp!: string
  deviceBatteryLevel?: number
  deviceNetwork?: DeviceNetwork
  contexts!: Array<GazelleRef<VixenCore.INamed>>
  location?: Location
  language!: string
  source?: string
  settings!: any
  target!: GazelleRef<VixenCore.IRoutable>
  parameters!: any

  constructor(attrs: IRoutableStarted & WithRef<RoutableStarted>) {
    super(attrs)
  }
}

export interface RoutableEndedSchema extends EventSchema {
  target: GazelleRefSchema
  'started-event': GazelleRefSchema
  'start-time': string
  'end-time': string
  parameters: any
}

export interface IRoutableEnded extends IEvent {
  target: GazelleRef<VixenCore.IRoutable>
  startedEvent: GazelleRef<RoutableStarted>
  startTime: string
  endTime: string
  parameters: any
}

type RoutableEndedSchemaType = z.Schema<
  RoutableEnded,
  z.ZodTypeDef,
  RoutableEndedSchema
>

export const RoutableEndedSchema = z
  .lazy(() =>
    z.object({
      ref: GazelleRefSchema,
      timestamp: z.string(),
      'device-battery-level': z.number().nullish(),
      'device-network': z.nativeEnum(DeviceNetwork).nullish(),
      contexts: z.array(GazelleRefSchema),
      location: LocationSchema.nullish(),
      language: z.string(),
      source: z.string().nullish(),
      settings: z.object({}).passthrough(),
      target: GazelleRefSchema,
      'started-event': GazelleRefSchema,
      'start-time': z.string(),
      'end-time': z.string(),
      parameters: z.object({}).passthrough(),
    }),
  )
  .transform(
    value => new RoutableEnded(recase(value)),
  ) satisfies RoutableEndedSchemaType

export class RoutableEnded
  extends Entity<RoutableEndedSchema>
  implements IRoutableEnded
{
  static readonly typename = 'routable-ended'
  static readonly schema: RoutableEndedSchemaType = RoutableEndedSchema
  static readonly parse = RoutableEndedSchema.parse
  static readonly fields = [
    'ref',
    'timestamp',
    'device-battery-level',
    'device-network',
    'contexts',
    'location',
    'language',
    'source',
    'settings',
    'target',
    'started-event',
    'start-time',
    'end-time',
    'parameters',
  ]

  readonly typename = 'routable-ended'

  ref!: GazelleRef<RoutableEnded>
  timestamp!: string
  deviceBatteryLevel?: number
  deviceNetwork?: DeviceNetwork
  contexts!: Array<GazelleRef<VixenCore.INamed>>
  location?: Location
  language!: string
  source?: string
  settings!: any
  target!: GazelleRef<VixenCore.IRoutable>
  startedEvent!: GazelleRef<RoutableStarted>
  startTime!: string
  endTime!: string
  parameters!: any

  constructor(attrs: IRoutableEnded & WithRef<RoutableEnded>) {
    super(attrs)
  }
}

export interface SubscribableStartedSchema extends EventSchema {
  target: GazelleRefSchema
}

export interface ISubscribableStarted extends IEvent {
  target: GazelleRef<VixenCore.ISubscribable>
}

type SubscribableStartedSchemaType = z.Schema<
  SubscribableStarted,
  z.ZodTypeDef,
  SubscribableStartedSchema
>

export const SubscribableStartedSchema = z
  .lazy(() =>
    z.object({
      ref: GazelleRefSchema,
      timestamp: z.string(),
      'device-battery-level': z.number().nullish(),
      'device-network': z.nativeEnum(DeviceNetwork).nullish(),
      contexts: z.array(GazelleRefSchema),
      location: LocationSchema.nullish(),
      language: z.string(),
      source: z.string().nullish(),
      settings: z.object({}).passthrough(),
      target: GazelleRefSchema,
    }),
  )
  .transform(
    value => new SubscribableStarted(recase(value)),
  ) satisfies SubscribableStartedSchemaType

export class SubscribableStarted
  extends Entity<SubscribableStartedSchema>
  implements ISubscribableStarted
{
  static readonly typename = 'subscribable-started'
  static readonly schema: SubscribableStartedSchemaType =
    SubscribableStartedSchema
  static readonly parse = SubscribableStartedSchema.parse
  static readonly fields = [
    'ref',
    'timestamp',
    'device-battery-level',
    'device-network',
    'contexts',
    'location',
    'language',
    'source',
    'settings',
    'target',
  ]

  readonly typename = 'subscribable-started'

  ref!: GazelleRef<SubscribableStarted>
  timestamp!: string
  deviceBatteryLevel?: number
  deviceNetwork?: DeviceNetwork
  contexts!: Array<GazelleRef<VixenCore.INamed>>
  location?: Location
  language!: string
  source?: string
  settings!: any
  target!: GazelleRef<VixenCore.ISubscribable>

  constructor(attrs: ISubscribableStarted & WithRef<SubscribableStarted>) {
    super(attrs)
  }
}

export interface SubscribableEndedSchema extends EventSchema {
  target: GazelleRefSchema
  'started-event': GazelleRefSchema
  'start-time': string
  'end-time': string
}

export interface ISubscribableEnded extends IEvent {
  target: GazelleRef<VixenCore.ISubscribable>
  startedEvent: GazelleRef<SubscribableStarted>
  startTime: string
  endTime: string
}

type SubscribableEndedSchemaType = z.Schema<
  SubscribableEnded,
  z.ZodTypeDef,
  SubscribableEndedSchema
>

export const SubscribableEndedSchema = z
  .lazy(() =>
    z.object({
      ref: GazelleRefSchema,
      timestamp: z.string(),
      'device-battery-level': z.number().nullish(),
      'device-network': z.nativeEnum(DeviceNetwork).nullish(),
      contexts: z.array(GazelleRefSchema),
      location: LocationSchema.nullish(),
      language: z.string(),
      source: z.string().nullish(),
      settings: z.object({}).passthrough(),
      target: GazelleRefSchema,
      'started-event': GazelleRefSchema,
      'start-time': z.string(),
      'end-time': z.string(),
    }),
  )
  .transform(
    value => new SubscribableEnded(recase(value)),
  ) satisfies SubscribableEndedSchemaType

export class SubscribableEnded
  extends Entity<SubscribableEndedSchema>
  implements ISubscribableEnded
{
  static readonly typename = 'subscribable-ended'
  static readonly schema: SubscribableEndedSchemaType = SubscribableEndedSchema
  static readonly parse = SubscribableEndedSchema.parse
  static readonly fields = [
    'ref',
    'timestamp',
    'device-battery-level',
    'device-network',
    'contexts',
    'location',
    'language',
    'source',
    'settings',
    'target',
    'started-event',
    'start-time',
    'end-time',
  ]

  readonly typename = 'subscribable-ended'

  ref!: GazelleRef<SubscribableEnded>
  timestamp!: string
  deviceBatteryLevel?: number
  deviceNetwork?: DeviceNetwork
  contexts!: Array<GazelleRef<VixenCore.INamed>>
  location?: Location
  language!: string
  source?: string
  settings!: any
  target!: GazelleRef<VixenCore.ISubscribable>
  startedEvent!: GazelleRef<SubscribableStarted>
  startTime!: string
  endTime!: string

  constructor(attrs: ISubscribableEnded & WithRef<SubscribableEnded>) {
    super(attrs)
  }
}

export type INTERFACES = VixenCoreManifest.INTERFACES &
  VixenCore.INTERFACES &
  VixenAssets.INTERFACES &
  VixenSpatial.INTERFACES & {
    event: IEvent
  }

export const ENTITIES = {
  ...VixenCoreManifest.ENTITIES,
  ...VixenCore.ENTITIES,
  ...VixenAssets.ENTITIES,
  ...VixenSpatial.ENTITIES,
  'app-state-changed': AppStateChanged,
  'app-reset': AppReset,
  'playable-started': PlayableStarted,
  'playable-ended': PlayableEnded,
  'visitable-started': VisitableStarted,
  'visitable-ended': VisitableEnded,
  'routable-started': RoutableStarted,
  'routable-ended': RoutableEnded,
  'subscribable-started': SubscribableStarted,
  'subscribable-ended': SubscribableEnded,
}
export type ENTITIES = {
  [K in keyof typeof ENTITIES]: InstanceType<(typeof ENTITIES)[K]>
}

export class Loader extends BundleLoader {
  static readonly ENTITIES = ENTITIES
  static readonly schemaVersion = '0.11.0'

  get<K extends keyof ENTITIES>(ref: {
    typename: K
    id: string
  }): ENTITIES[K] | undefined
  get<T extends ValueOf<ENTITIES> | ValueOf<INTERFACES> | Entity<any>>(
    ref: GazelleRef<T>,
  ): (T extends Entity<any> ? T : ReffableEntity<T> & T) | undefined
  get(ref: any) {
    return super.get(ref)
  }

  getAll<K extends keyof ENTITIES>(typename: K): Array<ENTITIES[K]>
  getAll<T extends ValueOf<ENTITIES>>(klass: GazelleEntityFactory<T>): Array<T>
  getAll(klass: any) {
    return super.getAll(klass)
  }

  getSingleton<K extends keyof ENTITIES>(typename: K): ENTITIES[K] | undefined
  getSingleton<T extends ValueOf<ENTITIES>>(
    klass: GazelleEntityFactory<T>,
  ): T | undefined
  getSingleton(klass: any) {
    return super.getSingleton(klass)
  }
}
