/** **************************************************************************************
 * Cache control
 * ************************************************************************************** */

import { CacheableResponsePlugin } from 'workbox-cacheable-response'
import { ExpirationPlugin } from 'workbox-expiration'
import {
  cleanupOutdatedCaches,
  createHandlerBoundToURL,
  precacheAndRoute,
} from 'workbox-precaching'
import { RangeRequestsPlugin } from 'workbox-range-requests'
import { NavigationRoute, registerRoute } from 'workbox-routing'
import {
  CacheFirst,
  NetworkFirst,
  StaleWhileRevalidate,
} from 'workbox-strategies'

import getEnv from '../utils/getEnv'

const { VITE_APP_CONTENT_DOMAIN } = getEnv()

const DOMAIN = VITE_APP_CONTENT_DOMAIN.replace(/\//g, '\\/')

const denylist: undefined | RegExp[] = [
  // If this looks like a URL for a resource, because it contains // a file extension
  new RegExp('/[^/?]+\\.[^/]+$'),
  // If this is a URL that starts with /_
  new RegExp('/^/_/'),
  // If this is a URL that starts with /.well-known
  new RegExp('/.well-known/'),
]

// Precache all of the assets generated by your build process.
// Their URLs are injected into the manifest variable below.
// This variable must be present somewhere in your service worker file,
// even if you decide not to use precaching. See https://cra.link/PWA
cleanupOutdatedCaches()
precacheAndRoute(self.__WB_MANIFEST)

// Set up App Shell-style routing, so that all navigation requests
// are fulfilled with your index.html shell. Learn more at
// https://developers.google.com/web/fundamentals/architecture/app-shell
// eslint-disable-next-line prefer-regex-literals
registerRoute(
  new NavigationRoute(createHandlerBoundToURL('/index.html'), { denylist }),
)

// Caches the asset-manifest.json file (this is the hash of all assets)
// This is not the PWA manifest
registerRoute(
  ({ url }) => url.pathname.startsWith('/asset-manifest.json'),
  new NetworkFirst({
    cacheName: 'pwa-assets-manifest',
    plugins: [
      new CacheableResponsePlugin({
        statuses: [0, 200],
      }),
      new ExpirationPlugin({ maxEntries: 1 }), // Will cache maximum 1 requests.
    ],
  }),
)

// --- Fonts ---

// Cache the Google Fonts stylesheets with a stale-while-revalidate strategy.
// @see https://developers.google.com/web/tools/workbox/guides/common-recipes#google_fonts
registerRoute(
  ({ url }) => url.origin === 'https://fonts.googleapis.com',
  new StaleWhileRevalidate({
    cacheName: 'google-fonts-stylesheets',
  }),
)

// Cache the underlying font files with a cache-first strategy for 1 year.
// @see https://developers.google.com/web/tools/workbox/guides/common-recipes#google_fonts
registerRoute(
  ({ url }) => url.origin === 'https://fonts.gstatic.com',
  new CacheFirst({
    cacheName: 'google-fonts-webfonts',
    plugins: [
      new CacheableResponsePlugin({
        statuses: [0, 200],
      }),
      new ExpirationPlugin({
        maxAgeSeconds: 60 * 60 * 24 * 365,
        maxEntries: 30,
      }),
    ],
  }),
)

// --- Content Bundles ---

// Get the latest Pladia manifest file entrypoint from the network first
// It's the only file in the published set that's not immutable
// We only bother to cache public files
const LATEST_CONTENT_BUNDLE_REGEX = RegExp(
  `${DOMAIN}\/bundles\/public\/latest\/[^\/\.]+.json`,
  'i',
)

registerRoute(
  ({ url }) => {
    return (
      url.origin === VITE_APP_CONTENT_DOMAIN &&
      LATEST_CONTENT_BUNDLE_REGEX.test(url.href)
    )
  },
  new NetworkFirst({
    cacheName: 'content-latest',
  }),
)

// Bundle files other than latest/ are static
// We only bother to cache public files
const CONTENT_BUNDLE_REGEX = RegExp(
  `${DOMAIN}\/bundles\/public\/[^\/]+\/`,
  'i',
)

registerRoute(
  ({ url }) => {
    return (
      url.origin === VITE_APP_CONTENT_DOMAIN &&
      CONTENT_BUNDLE_REGEX.test(url.href) &&
      !LATEST_CONTENT_BUNDLE_REGEX.test(url.href)
    )
  },
  new CacheFirst({
    cacheName: 'content',
    plugins: [
      new ExpirationPlugin({
        maxAgeSeconds: 60 * 60 * 24 * 365,
        // There are a minimum of 3 files, or more if there are multiple venues
        maxEntries: 30,
      }),
    ],
  }),
)

// --- Assets ---

// We use CacheFirst for assets because assets are immutable.
//
// See https://developers.google.com/web/tools/workbox/guides/common-recipes#caching_images
registerRoute(
  ({ request }) => request.destination === 'image',
  new CacheFirst({
    cacheName: 'image-cache',
    plugins: [
      new CacheableResponsePlugin({
        statuses: [0, 200],
      }),
      new ExpirationPlugin({
        // This is an estimation of a number of objects * breakpoints,
        // just to keep the cache a meaningful size
        maxEntries: 10000,
      }),
    ],
  }),
)

// We use CacheFirst for assets because assets are immutable.
//
// See https://developer.chrome.com/docs/workbox/serving-cached-audio-and-video
registerRoute(
  ({ request }) =>
    request.destination === 'audio' || request.destination === 'video',
  new CacheFirst({
    cacheName: 'media-cache',
    plugins: [
      new CacheableResponsePlugin({
        statuses: [0, 200],
      }),
      new ExpirationPlugin({
        maxEntries: 100,
      }),
      new RangeRequestsPlugin(),
    ],
  }),
)
