import { openDB, type IDBPDatabase, DBSchema } from 'idb'

import { WorkerFetchResultType } from '../types/worker'
import { debugLog } from '../utils/debug'
import { sendAnalyticsEvent } from '../utils/events'
import { delay } from '../utils/general'
import { assertNever } from '../utils/guards'

const INITIAL_RETRY_DELAY_MS = 1000
const DELAY_MULTIPLIER = 2

const DB_NAME = 'komodo-queue' as const
/** Bump the schema version to force a complete rebuild of the database */
const DB_SCHEMA_VERSION = 1 as const
const STORE_NAME = 'events-store' as const

type KomodoQueueKeyValue = {
  key: number
  /**
   * The event group to be sent to Komodo.
   *
   * This has been validated in the main thread.
   */
  value: unknown
}

/** This is the shape of the database */
interface DBType extends DBSchema {
  [STORE_NAME]: {
    key: KomodoQueueKeyValue['key']
    value: KomodoQueueKeyValue['value']
  }
}

/**
 * A queue for Komodo events to be added when the app is offline.
 *
 * Couldn't use the `Queue` class workbox-background-sync provides as it
 * doesn't work consistently across app refreshes and new tabs.
 */
export class KomodoQueueDB {
  public static syncTag = 'komodo-queue-sync'

  private retryDelay: number = INITIAL_RETRY_DELAY_MS

  private queuePromise: Promise<void> | null = null

  /**
   * A promise to open the database.
   *
   * Each call makes a separate request to open the database. This is to avoid
   * the issue in APM-1772, where the DB can be closed.
   */
  private get db(): Promise<IDBPDatabase<DBType>> {
    return openDB<DBType>(DB_NAME, DB_SCHEMA_VERSION, {
      upgrade: (db, oldVersion, newVersion) => {
        debugLog(DB_NAME, `upgrade needed: ${oldVersion} -> ${newVersion}`)

        this.upgradeDB(db)
      },
    })
  }

  private upgradeDB(db: IDBPDatabase<DBType>): void {
    try {
      db.deleteObjectStore(STORE_NAME)
    } catch (exc) {}

    db.createObjectStore(STORE_NAME, { autoIncrement: true })
  }

  public get isSyncing(): boolean {
    return !!this.queuePromise
  }

  private async processQueue(): Promise<void> {
    let entry: KomodoQueueKeyValue | null = null

    while ((entry = await this.pick())) {
      // Waits for the app to get online
      if (!navigator.onLine) {
        // Just shutdown the queue when offline
        // as it will be restarted when we're back online
        break
      }

      // Delay between items
      // increases exponentially when there's an error
      const { status } = await sendAnalyticsEvent(entry.value)

      switch (status) {
        case WorkerFetchResultType.Error:
          await delay(this.retryDelay)
          this.retryDelay *= DELAY_MULTIPLIER
          break

        case WorkerFetchResultType.Success:
          await this.remove(entry.key)
          // If the request was successful, reset the retry delay
          // so we don't have to wait a long time to send the next item
          this.retryDelay = INITIAL_RETRY_DELAY_MS
          break

        case WorkerFetchResultType.TerminalError:
          // Just remove it from the queue if it's a terminal error
          // as we can't process it.
          await this.remove(entry.key)
          break

        default:
          assertNever(status)
      }
    }
  }

  public async enqueue(item: unknown): Promise<void> {
    const store = (await this.db)
      .transaction(STORE_NAME, 'readwrite')
      .objectStore(STORE_NAME)

    await store.add(item)
  }

  public async remove(id: number): Promise<void> {
    const store = (await this.db)
      .transaction(STORE_NAME, 'readwrite')
      .objectStore(STORE_NAME)

    await store.delete(id)
  }

  /**
   * Gets the first item from the queue without removing it.
   * @returns The first item in the queue or null if the queue is empty.
   */
  public async pick(): Promise<KomodoQueueKeyValue | null> {
    const store = (await this.db)
      .transaction(STORE_NAME, 'readwrite')
      .objectStore(STORE_NAME)

    const cursor = await store.openCursor()

    if (!cursor) {
      return null
    }

    return cursor
  }

  public async sync(): Promise<void> {
    if (this.isSyncing) return
    try {
      this.queuePromise = this.processQueue()
      await this.queuePromise
    } catch (err) {
      throw err
    } finally {
      this.queuePromise = null
    }
  }
}
