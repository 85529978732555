/** **************************************************************************************
 * Analytics Event queueing
 * ************************************************************************************** */

import { KomodoQueueDB } from '../storage/KomodoQueueDB'
import { AnalyticsEvent, AnalyticsQueueSyncEvent } from '../types/worker'

const analyticsQueue = new KomodoQueueDB()

// Guarantees the sync is run when the app is online
export async function syncAnalyticsQueue(
  _: AnalyticsQueueSyncEvent,
): Promise<void> {
  await analyticsQueue.sync()
}

export async function analyticsEvent({
  eventSend,
}: AnalyticsEvent): Promise<void> {
  await analyticsQueue.enqueue(eventSend)
  // Starts the queue if it's not running as we want to try to send the event straight await,
  // we'll also wait for it to finish so the sw doesn't shutdown while the queue is being processed
  await analyticsQueue.sync()
}
