/* eslint-disable no-underscore-dangle */
/* eslint-disable no-restricted-globals */

// This service worker can be customized!
// See https://developers.google.com/web/tools/workbox/modules
// for the list of available Workbox modules, or add any other
// code you'd like.
// You can also remove this file if you'd prefer not to use a
// service worker, and the Workbox build step will be skipped.

import { clientsClaim } from 'workbox-core'

import { ToWorkerEventHandlerMap, WorkerEventSchema } from '../types/worker'

import { analyticsEvent, syncAnalyticsQueue } from './analyticsQueue'
import { cacheAssets } from './prefetchAssets'

// Initialise cache control, and how URLs are handled by the service worker for
// caching
import './registerRoute'

// Register event handlers for messages coming from the main process
const EVENTS: ToWorkerEventHandlerMap = {
  CACHE_ASSETS: event => cacheAssets(event),
  ANALYTICS_EVENT: event => analyticsEvent(event),
  ANALYTICS_QUEUE_SYNC_EVENT: event => syncAnalyticsQueue(event),
}

// Receives an event from the app, then based on the type of event,
// it will execute the corresponding handler
self.addEventListener('message', event => {
  const data = WorkerEventSchema.parse(event.data)
  const eventFn = EVENTS[data.type]
  if (!eventFn) {
    return
  }
  event.waitUntil(eventFn(event.data))
})

clientsClaim()

// We are not wrapping it in a 'message' event as per the new update.
// See https://developers.google.com/web/tools/workbox/modules/workbox-core
self.skipWaiting()
