export enum SEARCH_PARAMS {
  /** Search string (wall-id) */
  SEARCH = 'search',
  /** Next parameter (for welcome screen) */
  NEXT = 'next',

  /** Override vx-config (for debugging) */
  VX_CONFIG = 'vx-config',
  /** Override bundle audience */
  AUDIENCE = 'audience',
  /** Override immersive tour (for debugging) */
  IMMERSIVE_TOUR = 'immersive-tour',
  /** Override step on welcome screen (for debugging) */
  STEP = 'step',
}

export enum LOCAL_STORAGE {
  /** The currently chosen experience */
  EXPERIENCE = 'EXPERIENCE',
  /** The currently chosen theme variant (light/dark) */
  THEME_VARIANT = 'THEME_VARIANT',
  /** The device ID */
  DEVICE_ID = 'DEVICE_ID',
  /** The manifest ref */
  EVENT_MANIFEST = 'EVENT_MANIFEST',
  /**
   * Stores the time for the latest onboarding so we can determine if we should
   * reset the onboarding state by checking if it was before midnight today.
   */
  LATEST_ONBOARDING_TIME = 'LATEST_ONBOARDING_TIME',
  /** ID of the chosen VxConfig */
  VX_CONFIG = 'VX_CONFIG',
  /** Bundle audience */
  AUDIENCE = 'AUDIENCE',
  /**
   * Stores the user's estimated location.
   * This is a location based on pages the user visits.
   */
  ESTIMATED_LOCATION = 'LOCATION',
}
